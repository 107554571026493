import { graphql, readInlineData } from "react-relay";

import type { hooksTopic$key } from "scmp-app/queries/__generated__/hooksTopic.graphql";

const topicTypes = ["person", "company", "place_locations"];
export type TopicTypes = (typeof topicTypes)[number];

const topicTypeMapping: Record<TopicTypes, string> = {
  company: "company",
  person: "people",
  place_locations: "location",
};

export const useTopicType = (reference: hooksTopic$key) => {
  const topic = readInlineData(
    graphql`
      fragment hooksTopic on Topic @inline {
        types
        sponsor {
          __typename
        }
      }
    `,
    reference,
  );

  const hasSponsor = !!topic.sponsor;
  const firstMatch = topicTypes.find(value => topic.types?.includes(value));
  let currentType = "general";
  const isSuperTopic = topic.types?.includes("super");

  if (firstMatch) {
    currentType = topicTypeMapping[firstMatch];
  }

  return {
    currentType,
    hasSponsor,
    isSuperTopic,
  };
};
